import Services from 'src/components/ClubComponents/Servicos'
import Seo from 'src/components/seo/Seo'

function Page() {
  return (
    <>
      <Seo title="Clube | Serviços | Decathlon" />
      <Services />
    </>
  )
}

export default Page
